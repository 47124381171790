/* eslint-disable react/no-danger */
import Head from 'next/head';

const Home = () => (
  <div>
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
              const domain = window.location.href.split(".").splice(1).join(".")
              if(domain && domain.length > 0) window.location.href = "https://"+domain
            `,
        }}
      />
    </Head>
  </div>
);

export default Home;
